/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Logo from "../src/components/ui/Logo";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong",
    em: "em",
    a: "a",
    blockquote: "blockquote",
    pre: "pre",
    code: "code",
    hr: "hr",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "hello there."), "\n", React.createElement(_components.p, null, "this part is ", React.createElement(_components.strong, null, "bold"), ". This one is ", React.createElement(_components.em, null, "italic"), "."), "\n", React.createElement(_components.p, null, "this is a ", React.createElement(_components.a, {
    href: "google.com"
  }, "link to something")), "\n", React.createElement(Logo), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "this is a blockquote?"), "\n"), "\n", React.createElement(_components.p, null, "some code:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const a = \"something\";\nlet a = {\n  formattedPrettyCool: true,\n};\n")), "\n", React.createElement(_components.p, null, "an inline ", React.createElement(_components.code, null, "code"), " example."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, "Anyway"), "\n", React.createElement(_components.p, null, "Here's some more stuff."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "one thing"), "\n", React.createElement(_components.li, null, "another thing"), "\n", React.createElement(_components.li, null, "a third thing"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
