import React from "react";
import * as classes from "./Logo.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const Logo = () => (
  <StaticImage
    alt="Save New College logo"
    src="../../images/logo.png"
    className={classes["logo"]}
  />
);

export default Logo;
